
#id669bed9d914da0d427ff1e2b {
  #ixru {
min-height : 100px; 
font-family : "Italiana", sans-serif; 
} 
#ij34 {
padding : 10px 10% 10px 10%; 
display : flex; 
justify-content : space-between; 
flex-wrap : wrap; 
} 
#i7z62 {
color : black; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#iep8l {
width : auto; 
display : flex; 
justify-content : flex-start; 
align-items : center; 
} 
#izcqh {
padding : 10px; 
display : block; 
color : white; 
} 
.icono-social {
width : 25px; 
} 
.item-social {
margin : 0px 10px 0px 10px; 
padding : 10px 10px 10px 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(217,217,217,0.2) 0%, rgba(217,217,217,0.2) 100%); 
width : 88px; 
min-height : 88px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
border : 1px solid #ffffff; 
border-radius : 50% 50% 50% 50%; 
} 
#i7mce {
padding : 10px; 
font-family : WindSong; 
color : #3ab5dd; 
} 
#icviq {
padding : 10px; 
width : 479.46759033203125px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
font-weight : 600; 
flex-wrap : wrap; 
font-size : 22px; 
} 
#ie1gg {
padding : 5px; 
width : auto; 
height : auto; 
display : block; 
color : black; 
text-decoration : none; 
} 
#iierj {
padding : 5px; 
width : auto; 
height : auto; 
display : block; 
color : #000000; 
text-decoration : none; 
} 
#iotjc {
padding : 5px; 
width : auto; 
height : auto; 
display : block; 
color : #000000; 
} 
.activo-pagina {
color : #b70000; 
text-decoration : none; 
} 
#i0kwi {
padding : 80px 10% 80px 10%; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#303030 0%, #303030 100%); 
} 
#ihonp {
padding : 60px 10% 60px 10%; 
position : relative; 
min-height : 752px; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#i367ii {
min-height : 100px; 
padding : 10px 0px 10px 0px; 
width : 50%; 
} 
#illm7i {
padding : 10px 0px 10px 0px; 
} 
#ihr8s4 {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#ifrzbt {
background-color : transparent; 
} 
#i65x8n {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#iygjnf {
background-color : transparent; 
} 
#i269cz {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#iyid9o {
background-color : transparent; 
} 
#ispf5r {
padding : 10px; 
display : block; 
font-size : 45px; 
text-align : center; 
color : white; 
} 
.campos-contacto {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#3c3c3c 0%, #3c3c3c 100%); 
width : 100%; 
padding : 10px 10px 10px 10px; 
border : 2px solid #ffffff; 
font-family : Montserrat; 
color : #ffffff; 
} 
#ivb8of {
padding : 10px; 
display : flex; 
justify-content : flex-end; 
} 
#im973i {
padding : 10px 20px 10px 20px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(246,0,0,0.5) 0%, rgba(246,0,0,0.5) 100%); 
color : #ffffff; 
font-size : 24px; 
font-family : Montserrat; 
width : 160px; 
} 
#imtnsk {
padding : 10px; 
} 
#i2qszk {
padding : 10px; 
display : block; 
color : #ffffff; 
text-align : center; 
} 
.item {
height : 200px; 
margin-top : 50px; 
margin-right : auto; 
margin-bottom : 50px; 
margin-left : auto; 
padding-top : 75px; 
text-align : center; 
} 
#i0uyoc {
min-height : 100px; 
display : flex; 
} 
.tarjeta-testimonial {
width : 300px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.2) ; 
margin : 70px 10px 10px 10px; 
} 
#infbh {
color : #b70000; 
} 
#ikq21p {
color : black; 
width : 500px; 
} 
.backgroundprincipal {
font-family : Montserrat; 
__background-type : image; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk2ilvvpyf8i.png'); 
} 
#iu4568 {
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-around; 
} 
#i5168l {
color : black; 
} 
#ivsl9l {
color : black; 
width : 15px; 
} 
#ijorsa {
color : black; 
} 
#i13qdh {
padding : 10px 10% 10px 10%; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-around; 
} 
#ipxsda {
padding : 10px; 
display : flex; 
justify-content : space-around; 
align-self : center; 
} 
.item-info {
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
flex-direction : row; 
} 
#ikxkxh {
padding : 10px; 
min-height : 100px; 
width : 50%; 
display : flex; 
align-items : center; 
justify-content : space-around; 
} 
#i5ajok {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#igc5kf {
padding : 10px 10% 10px 10%; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
} 
#i1fr26 {
color : black; 
} 
#is5eph {
padding : 10px; 
min-height : 100px; 
width : 147px; 
display : flex; 
align-items : center; 
justify-content : space-between; 
} 
#imj1uz {
display : block; 
padding : 10px 10px 10px 10px; 
border-radius : 10px 10px 10px 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(246,0,0,0.5) 0%, rgba(246,0,0,0.5) 100%); 
} 
#id3wfp {
color : black; 
} 
#i3qsiz {
color : black; 
} 
#iak5x5 {
display : block; 
padding : 10px 10px 10px 10px; 
border-radius : 10px 10px 10px 10px; 
} 
.circulo {
width : 50px; 
min-height : 50px; 
} 
#iay8fh {
color : #ffffff; 
} 
#iak5x5:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(246,0,0,0.5) 0%, rgba(246,0,0,0.5) 100%); 
} 
.img-sobremi {
margin : 35px 0px 35px 0px; 
} 
.swiper {
width : 100%; 
height : 300px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.swiper.mySwiper {
display : none; 
} 
#i2z46 {
min-height : 100px; 
padding : 10px; 
font-family : Verdana, Geneva, sans-serif; 
} 
#i2agd {
padding : 5px; 
text-align : center; 
color : #b70000; 
font-size : 3rem; 
margin : 5px 0 50px 0; 
} 
#iqv9ro {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 

  @media only screen and (max-width: 992px) {#ij34 {
padding : 10px 10px 10px 10px; 
}} 
@media only screen and (max-width: 992px) {#icviq {
width : 319.07682373046873px; 
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#ihonp {
padding : 10px 10px 10px 10px; 
min-height : 439px; 
}} 
@media only screen and (max-width: 992px) {.tarjeta-testimonial {
width : 225px; 
}} 
@media only screen and (max-width: 992px) {.item-info {
justify-content : space-around; 
}} 
@media only screen and (max-width: 992px) {.item-social {
width : 50px; 
min-height : 50px; 
}} 
@media only screen and (max-width: 992px) {#i13qdh {
display : block; 
padding : 10px 10px 10px 10px; 
}} 
@media only screen and (max-width: 992px) {#ipxsda {
width : 208.562px; 
}} 
@media only screen and (max-width: 992px) {#i0uyoc {
flex-wrap : wrap; 
}} 
@media only screen and (max-width: 992px) {.backgroundprincipal.ancho-100 {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ikq21p {
width : 250px; 
}} 
@media only screen and (max-width: 992px) {.img-sobremi {
display : none; 
}} 
@media only screen and (max-width: 992px) {.swiper.mySwiper {
display : block; 
height : auto; 
}} 
@media only screen and (max-width: 992px) {.swiper-button-next {
color : #cb0000; 
}} 
@media only screen and (max-width: 992px) {.swiper-button-prev {
color : #cb0000; 
}} 

  @media only screen and (max-width: 480px) {#ispf5r {
font-size : 35px; 
}} 
@media only screen and (max-width: 480px) {.tarjeta-testimonial {
width : 250px; 
}} 
@media only screen and (max-width: 480px) {#iep8l {
width : 100%; 
justify-content : space-around; 
}} 
@media only screen and (max-width: 480px) {#ikq21p {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#icviq {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {.item-info {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#ikxkxh {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i367ii {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#im973i {
font-size : 16px; 
}} 
@media only screen and (max-width: 480px) {#izcqh {
font-size : 12px; 
}} 
@media only screen and (max-width: 480px) {#iu4568 {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#is5eph {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {.swiper-button-next {
width : 27px; 
}} 
@media only screen and (max-width: 480px) {.backgroundprincipal {
font-size : 14px; 
padding : 10px 3px 10px 3px; 
}} 

}
  