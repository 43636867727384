
#id666713a4914da0d427fd7c11 {
  #ixru {
min-height : 100px; 
font-family : "Italiana", sans-serif; 
} 
#ij34 {
padding : 10px 5% 10px 5%; 
display : flex; 
justify-content : space-between; 
flex-wrap : wrap; 
} 
#i7z62 {
color : black; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#iep8l {
width : auto; 
display : flex; 
justify-content : flex-start; 
align-items : center; 
text-decoration : none; 
} 
#izcqh {
padding : 10px; 
display : block; 
color : white; 
} 
.icono-social {
width : 25px; 
} 
.item-social {
margin : 0px 10px 0px 10px; 
padding : 10px 10px 10px 10px; 
border : 1px solid #ffffff; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(217,217,217,0.2) 0%, rgba(217,217,217,0.2) 100%); 
border-radius : 50% 50% 50% 50%; 
width : 70px; 
min-height : 70px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#ilpyc {
padding : 10px 5% 0px 5%; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
} 
#i7mce {
padding : 10px; 
font-family : WindSong; 
color : #1f2830; 
} 
#icviq {
padding : 10px; 
width : 479.46759033203125px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
font-weight : 600; 
flex-wrap : wrap; 
margin : 0px 13vh 0px 0px; 
font-family : "Italiana", sans-serif; 
font-size : 22px; 
} 
#ie1gg {
padding : 5px; 
width : auto; 
height : auto; 
display : block; 
} 
#iierj {
padding : 5px; 
width : auto; 
height : auto; 
display : block; 
color : #000000; 
text-decoration : none; 
} 
#iotjc {
padding : 5px; 
width : auto; 
height : auto; 
display : block; 
color : #000000; 
text-decoration : none; 
} 
.activo-pagina {
color : #b70000; 
text-decoration : none; 
} 
#igcbq {
padding : 10px; 
min-height : 100px; 
width : 50%; 
display : flex; 
align-items : center; 
font-weight : 400; 
font-size : 53px; 
font-family : "Italiana", sans-serif; 
color : #f60000; 
text-align : center; 
} 
#i0idm {
padding : 10px 10px 0px 10px; 
min-height : 100px; 
width : 50%; 
display : flex; 
justify-content : flex-end; 
align-items : center; 
position : relative; 
} 
#iy1jt {
color : black; 
min-height : 10px; 
z-index : 2; 
margin : 0 5vh 0 0; 
width : 476.35900000000004px; 
} 
#i8mq1 {
padding : 60px 10% 60px 10%; 
color : white; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
} 
#i0kwi {
padding : 80px 10% 80px 10%; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#303030 0%, #303030 100%); 
} 
#ihonp {
padding : 120px 5% 65px 5%; 
min-height : 100px; 
justify-content : space-around; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f5f5f5 0%, #f5f5f5 100%); 
position : relative; 
} 
#ilb92 {
padding : 10px 0px 10px 10px; 
width : 55%; 
} 
#icntm {
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
box-shadow : 0 0 13px 0px rgba(0,0,0,0.37) ; 
border : 1px solid #f60000; 
width : 390px; 
} 
#iep08 {
color : black; 
min-height : 190auto; 
z-index : 3; 
} 
#iadgz {
padding : 0px 10px 10px 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
z-index : 15; 
position : relative; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#i367ii {
min-height : 100px; 
padding : 10px 0px 10px 0px; 
width : 50%; 
} 
#illm7i {
padding : 10px 0px 10px 0px; 
} 
#ihr8s4 {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#ifrzbt {
background-color : transparent; 
} 
#i65x8n {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#iygjnf {
background-color : transparent; 
} 
#i269cz {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#iyid9o {
background-color : transparent; 
} 
#ispf5r {
padding : 10px; 
display : block; 
font-size : 45px; 
text-align : center; 
color : white; 
font-family : "Italiana", sans-serif; 
align-items : center; 
} 
.campos-contacto {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#3c3c3c 0%, #3c3c3c 100%); 
width : 100%; 
padding : 10px 10px 10px 10px; 
border : 1px solid #fbfbfb; 
color : #ffffff; 
font-family : Montserrat; 
} 
#ivb8of {
padding : 10px; 
display : flex; 
justify-content : flex-end; 
} 
#im973i {
padding : 10px 20px 10px 20px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(246,0,0,0.5) 0%, rgba(246,0,0,0.5) 100%); 
color : #ffffff; 
width : 120px; 
} 
#imtnsk {
padding : 10px; 
} 
#i2qszk {
padding : 10px; 
display : block; 
color : #ffffff; 
text-align : center; 
font-family : Montserrat; 
} 
.item {
height : 200px; 
margin-top : 50px; 
margin-right : auto; 
margin-bottom : 50px; 
margin-left : auto; 
padding-top : 75px; 
text-align : center; 
} 
#i0uyoc {
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
} 
#idp6gy {
min-height : 100px; 
} 
#ikdqk8 {
min-height : 100px; 
align-items : flex-end; 
justify-content : flex-end; 
width : 45%; 
} 
#i4d9oj {
padding : 80px 5% 80px 5%; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f5f5f5 0%, #f5f5f5 100%); 
} 
.tarjeta-testimonial {
width : 300px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.2) ; 
margin : 70px 10px 10px 10px; 
} 
#i8x9qu {
min-height : 100px; 
} 
.backgroundprincipal {
__background-type : image; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk2ilvvpyf8i.png'); 
} 
#icoicf {
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
justify-content : space-around; 
font-family : Montserrat; 
} 
#ivczwg {
color : black; 
} 
#i2seiz {
color : black; 
width : 15px; 
} 
#ib3mif {
color : black; 
} 
#iomgs1 {
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
justify-content : space-around; 
width : 306px; 
} 
#iy3iba {
padding : 10px; 
display : flex; 
justify-content : space-around; 
align-self : center; 
} 
#i51eb8 {
color : black; 
width : 380px; 
} 
.backgroundprincipal.borderprincipal {
border-radius : 0px 0px 0px 0px; 
} 
#inasp7 {
min-height : 100px; 
padding : 10px; 
} 
#i2tk3u {
min-height : 100px; 
width : 75%; 
} 
#i2erdn {
min-height : 100px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : right top; 
background-attachment : scroll; 
background-size : contain; 
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk2ilvvkcqfk.png'); 
} 
#iqe0az {
padding : 10px; 
display : block; 
} 
#iizc3u {
color : black; 
} 
#iqx9ie {
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-around; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(217,217,217,0.24) 0%, rgba(217,217,217,0.24) 100%); 
border-radius : 50% 50% 50% 50%; 
} 
#ibp55k {
padding : 10px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
align-self : center; 
} 
#im3nng {
padding : 10px; 
display : block; 
text-align : center; 
font-weight : 400; 
font-size : 24px; 
} 
#ildkh6 {
padding : 10px; 
min-height : 100px; 
width : 300px; 
margin : 0px 0% 0px 5%; 
} 
#ijiuzf {
color : black; 
} 
#i6cwym {
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-around; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(217,217,217,0.24) 0%, rgba(217,217,217,0.24) 100%); 
border-radius : 50% 50% 50% 50%; 
} 
#ifo2x4 {
padding : 10px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
align-self : center; 
} 
#ina24s {
padding : 10px; 
display : block; 
text-align : center; 
font-weight : 400; 
font-size : 24px; 
} 
#i8b9rf {
padding : 10px; 
min-height : 100px; 
width : 300px; 
margin : 0 5% 0 0; 
} 
#iv9nbo {
color : black; 
} 
#iv1pfr {
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-around; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(217,217,217,0.24) 0%, rgba(217,217,217,0.24) 100%); 
border-radius : 50% 50% 50% 50%; 
} 
#i559j2 {
padding : 10px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
align-self : center; 
} 
#irrzh1 {
padding : 10px; 
display : block; 
text-align : center; 
font-weight : 400; 
font-size : 24px; 
} 
#i81gk1 {
padding : 10px; 
min-height : 100px; 
width : 300px; 
} 
#icregl {
padding : 10px; 
} 
.botonprincipal {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(246,0,0,0.56) 0%, rgba(246,0,0,0.56) 100%); 
color : #ffffff; 
text-decoration : none; 
width : 250px; 
text-align : center; 
padding : 10px 10px 10px 10px; 
font-size : 24px; 
} 
.botonprincipal:hover  {
color : #ffffff; 
text-decoration : none; 
} 
#ibxuth {
display : block; 
color : #f60000; 
font-size : 82px; 
text-align : right; 
z-index : 3; 
} 
#i6prvu {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(246,0,0,0.56) 0%, rgba(246,0,0,0.56) 100%); 
width : 53.7vw; 
z-index : 15; 
} 
#iypl0n {
padding : 10px; 
min-height : 222px; 
width : 65vw; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#d9d9d9 0%, #d9d9d9 100%); 
position : absolute; 
left : 0px; 
bottom : 0px; 
z-index : 0; 
} 
#ibeprf {
display : block; 
color : #f60000; 
font-size : 82px; 
text-align : right; 
z-index : 3; 
padding : 0px 25px 0px 0px; 
} 
#ijpivk {
padding : 10px; 
display : block; 
color : black; 
font-weight : 600; 
font-size : 24px; 
width : 244.938px; 
} 
#i0zovg {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
width : 45%; 
} 
#i9w69i {
padding : 10px; 
display : block; 
font-family : Montserrat; 
width : 55%; 
} 
#iscir2:hover  {
color : #ffffff; 
text-decoration : none; 
} 
#i77x6k {
display : inline-block; 
min-width : 50px; 
cursor : pointer; 
border-radius : 10px 10px 10px 10px; 
} 
#iwdl5i {
padding : 10px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#iq3gwi {
padding : 10px; 
width : 100%; 
} 
#i457uh {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
box-shadow : 0 0 13px 0px rgba(0,0,0,0.37) ; 
border : 1px solid #f60000; 
display : flex; 
flex-wrap : wrap; 
} 
#inlwzs {
padding : 10px 0px 10px 0px; 
min-height : 84px; 
position : relative; 
} 
#i2sqf7 {
padding : 10px; 
min-height : 42px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(246,0,0,0.56) 0%, rgba(246,0,0,0.56) 100%); 
position : absolute; 
width : 75%; 
z-index : 2; 
left : -5px; 
} 
#iw6aen {
padding : 10px; 
min-height : 42px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#d9d9d9 0%, #d9d9d9 100%); 
position : absolute; 
width : 50%; 
left : -5px; 
top : 33px; 
} 
#inu4vk {
text-decoration : none; 
} 
#iyuk7m {
font-family : "Italiana", sans-serif; 
} 
#iy7001 {
padding : 10px; 
min-height : 100px; 
width : 50%; 
display : flex; 
align-items : center; 
justify-content : space-around; 
} 
#ip85kg {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
#igevyf {
padding : 10px 10% 10px 10%; 
min-height : 100px; 
display : flex; 
justify-content : space-between; 
} 
#iy7pfg {
color : black; 
} 
.contentwrapperanimated {
width : 50px; 
height : 50px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
display : flex; 
flex-direction : row; 
justify-content : center; 
align-content : center; 
align-items : center; 
} 
.container_animated {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 90%; 
height : 90%; 
} 
.menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100vh; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
z-index : 1; 
} 
.menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.menu__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 30px; 
height : 40px; 
z-index : 2; 
} 
.span_middle {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_before {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_after {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.menu__close__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 25px; 
height : 30px; 
left : -100%; 
z-index : 2; 
} 
.span__close__left {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(45deg); 
} 
.span__close__right {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
top : 0px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(135deg); 
} 
.linkhrefs {
position : fixed; 
} 
#i6iq52 {
display : none; 
} 
#iu16e6 {
color : #ffffff; 
} 
#iiezql {
padding : 10px; 
min-height : 100px; 
width : 147px; 
display : flex; 
align-items : center; 
justify-content : space-between; 
} 
#ijdnxl {
padding : 10px 10px 10px 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border-radius : 10px 10px 10px 10px; 
} 
#iibs47 {
color : black; 
width : 35px; 
min-height : 10px; 
} 
#ildd5p {
color : black; 
} 
#iuuqpo {
padding : 10px 10px 10px 10px; 
border-radius : 10px 10px 10px 10px; 
} 
#iuuqpo:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
} 
#i89at6 {
display : block; 
color : #f60000; 
font-size : 82px; 
text-align : right; 
z-index : 3; 
padding : 0px 25px 0px 0px; 
} 
#ivhvdk {
min-height : 100px; 
} 
#i3ond6 {
padding : 10px; 
} 
#ifnkmn {
padding : 10px; 
min-height : 42px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(246,0,0,0.56) 0%, rgba(246,0,0,0.56) 100%); 
position : absolute; 
width : 75%; 
z-index : 2; 
left : -5px; 
} 
#ildeai {
padding : 10px; 
min-height : 42px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#d9d9d9 0%, #d9d9d9 100%); 
position : absolute; 
width : 50%; 
left : -5px; 
top : 33px; 
} 
#ip62cl {
padding : 10px 0px 10px 0px; 
min-height : 84px; 
position : relative; 
} 
#ip83sc {
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
box-shadow : 0 0 13px 0px rgba(0,0,0,0.37) ; 
border : 1px solid #f60000; 
width : 390px; 
} 
#comunicacion {
padding : 80px 5% 80px 5%; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f5f5f5 0%, #f5f5f5 100%); 
} 
#iyluno {
display : block; 
padding : 5px 5px 5px 5px; 
} 
#ica05k {
display : block; 
padding : 5px 5px 5px 5px; 
} 
#io2k6h {
display : block; 
padding : 5px 5px 5px 5px; 
} 
.img-item {
border-radius : 50% 50% 50% 50%; 
width : 200px; 
} 
#i5y73g {
min-height : 100px; 
padding : 10px; 
display : flex; 
justify-content : space-around; 
flex-wrap : wrap; 
} 
#i77vp4 {
padding : 10px; 
display : flex; 
} 
#ioioew {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
font-size : 44px; 
font-weight : 600; 
} 
#ijk3mr {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
color : #8b8b8b; 
font-family : Montserrat; 
} 
#imcik2 {
width : 100%; 
display : block; 
color : #000000; 
} 
#is7ns9 {
min-height : 100px; 
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-around; 
} 
#in6ldj {
padding : 10px; 
} 
#itm6rk {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
font-size : 44px; 
font-weight : 600; 
} 
#i0phvf {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
color : #8b8b8b; 
font-family : Montserrat; 
} 

  @media only screen and (max-width: 992px) {#ilpyc {
padding : 10px 10px 0px 10px; 
}} 
@media only screen and (max-width: 992px) {#ij34 {
padding : 10px 10px 10px 10px; 
}} 
@media only screen and (max-width: 992px) {#i7mce {
width : 365.44047119140623px; 
}} 
@media only screen and (max-width: 992px) {#icviq {
width : 319.07682373046873px; 
display : none; 
}} 
@media only screen and (max-width: 992px) {#igcbq {
font-size : 30px; 
}} 
@media only screen and (max-width: 992px) {#i8mq1 {
padding : 60px 10px 60px 10px; 
}} 
@media only screen and (max-width: 992px) {#ihonp {
padding : 120px 10px 120px 10px; 
}} 
@media only screen and (max-width: 992px) {#iadgz {
align-items : center; 
flex-direction : column; 
}} 
@media only screen and (max-width: 992px) {.tarjeta-testimonial {
width : 225px; 
}} 
@media only screen and (max-width: 992px) {#i4d9oj {
padding : 80px 10px 80px 10px; 
}} 
@media only screen and (max-width: 992px) {#ibiblr {
min-height : 100px; 
}} 
@media only screen and (max-width: 992px) {#ildkh6 {
width : 200px; 
}} 
@media only screen and (max-width: 992px) {#ivza5c {
min-height : 100px; 
}} 
@media only screen and (max-width: 992px) {#i8b9rf {
width : 200px; 
}} 
@media only screen and (max-width: 992px) {#iqtacu {
min-height : 100px; 
}} 
@media only screen and (max-width: 992px) {#i81gk1 {
width : 200px; 
}} 
@media only screen and (max-width: 992px) {#ina24s {
font-size : 17px; 
}} 
@media only screen and (max-width: 992px) {#irrzh1 {
font-size : 17px; 
}} 
@media only screen and (max-width: 992px) {#im3nng {
font-size : 17px; 
}} 
@media only screen and (max-width: 992px) {#iep08 {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ikdqk8 {
width : 100%; 
display : flex; 
justify-content : space-around; 
align-items : center; 
}} 
@media only screen and (max-width: 992px) {#ilb92 {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ibxuth {
font-size : 60px; 
}} 
@media only screen and (max-width: 992px) {#icntm {
width : 350px; 
}} 
@media only screen and (max-width: 992px) {#iy7001 {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#i367ii {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#igevyf {
flex-wrap : wrap; 
}} 
@media only screen and (max-width: 992px) {#iomgs1 {
width : 248.95299999999997px; 
}} 
@media only screen and (max-width: 992px) {.item-social {
width : 50px; 
min-height : 50px; 
}} 
@media only screen and (max-width: 992px) {#i6iq52 {
padding : 10px; 
min-height : 100px; 
width : 301px; 
display : flex; 
align-items : center; 
justify-content : flex-end; 
}} 
@media only screen and (max-width: 992px) {.span_before {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
}} 
@media only screen and (max-width: 992px) {.span_middle {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
}} 
@media only screen and (max-width: 992px) {.span_after {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
}} 
@media only screen and (max-width: 992px) {.menu__box {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(246,0,0,0.64) 0%, rgba(246,0,0,0.64) 100%); 
color : #ffffff; 
}} 
@media only screen and (max-width: 992px) {.menu__item {
color : #ffffff; 
}} 
@media only screen and (max-width: 992px) {#iiezql {
display : none; 
}} 
@media only screen and (max-width: 992px) {#ip83sc {
width : 350px; 
}} 
@media only screen and (max-width: 992px) {#comunicacion {
padding : 80px 10px 80px 10px; 
}} 

  @media only screen and (max-width: 480px) {#ilpyc {
padding : 10px 0px 0px 0px; 
display : block; 
}} 
@media only screen and (max-width: 480px) {#igcbq {
width : 100%; 
font-family : Tahoma, sans-serif; 
font-size : 25px; 
color : #000000; 
text-align : left; 
}} 
@media only screen and (max-width: 480px) {#i0idm {
justify-content : space-around; 
padding : 10px 0px 0px 0px; 
display : block; 
position : static; 
width : auto; 
}} 
@media only screen and (max-width: 480px) {#iy1jt {
min-height : 50px; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i8mq1 {
align-items : center; 
justify-content : space-around; 
}} 
@media only screen and (max-width: 480px) {#ilb92 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iadgz {
flex-wrap : wrap; 
flex-direction : column-reverse; 
}} 
@media only screen and (max-width: 480px) {#iep08 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ispf5r {
font-size : 35px; 
}} 
@media only screen and (max-width: 480px) {#ikdqk8 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {.tarjeta-testimonial {
width : 250px; 
margin : 70px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#iep8l {
justify-content : space-around; 
}} 
@media only screen and (max-width: 480px) {#icviq {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i51eb8 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i7mce {
width : 65%; 
}} 
@media only screen and (max-width: 480px) {#inasp7 {
width : auto; 
padding : 10px 60px 10px 10px; 
}} 
@media only screen and (max-width: 480px) {#igevyf {
justify-content : space-around; 
}} 
@media only screen and (max-width: 480px) {#i8b9rf {
margin : 0px 0% 0px 0px; 
}} 
@media only screen and (max-width: 480px) {#ildkh6 {
margin : 0px 0% 0px 0%; 
}} 
@media only screen and (max-width: 480px) {#ibxuth {
font-size : 35px; 
}} 
@media only screen and (max-width: 480px) {#i0zovg {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i9w69i {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ibeprf {
font-size : 35px; 
}} 
@media only screen and (max-width: 480px) {#icntm {
width : 245px; 
}} 
@media only screen and (max-width: 480px) {#i6iq52 {
width : auto; 
}} 
@media only screen and (max-width: 480px) {#icoicf {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#izcqh {
font-size : 12px; 
padding : 10px 0px 10px 10px; 
}} 
@media only screen and (max-width: 480px) {#iqe0az {
text-align : left; 
}} 
@media only screen and (max-width: 480px) {#i2erdn {
__background-type : image; 
background-repeat : no-repeat; 
background-position : right top; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk2ilvvkcqfk.png'); 
}} 
@media only screen and (max-width: 480px) {#i89at6 {
font-size : 35px; 
}} 
@media only screen and (max-width: 480px) {#ip83sc {
width : 245px; 
}} 

}
  