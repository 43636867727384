
#id666713c5914da0d427fd7c20 {
  #ixru {
min-height : 100px; 
font-family : "Italiana", sans-serif; 
} 
#ij34 {
padding : 10px 10% 10px 10%; 
display : flex; 
justify-content : space-between; 
flex-wrap : wrap; 
} 
#i7z62 {
color : black; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#iep8l {
width : auto; 
display : flex; 
justify-content : flex-start; 
align-items : center; 
} 
#izcqh {
padding : 10px; 
display : block; 
color : white; 
} 
.icono-social {
width : 25px; 
} 
.item-social {
margin : 0px 10px 0px 10px; 
padding : 10px 10px 10px 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(217,217,217,0.2) 0%, rgba(217,217,217,0.2) 100%); 
width : 88px; 
min-height : 88px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
border : 1px solid #ffffff; 
border-radius : 50% 50% 50% 50%; 
} 
#i7mce {
padding : 10px; 
font-family : WindSong; 
color : #3ab5dd; 
} 
#icviq {
padding : 10px; 
width : 479.46759033203125px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
font-weight : 600; 
flex-wrap : wrap; 
font-size : 22px; 
} 
#ie1gg {
padding : 5px; 
width : auto; 
height : auto; 
display : block; 
color : black; 
text-decoration : none; 
} 
#iierj {
padding : 5px; 
width : auto; 
height : auto; 
display : block; 
color : #000000; 
text-decoration : none; 
} 
#iotjc {
padding : 5px; 
width : auto; 
height : auto; 
display : block; 
color : #000000; 
} 
.activo-pagina {
color : #b70000; 
text-decoration : none; 
} 
#i0kwi {
padding : 80px 10% 80px 10%; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#303030 0%, #303030 100%); 
} 
#ihonp {
padding : 60px 10% 60px 10%; 
position : relative; 
min-height : 752px; 
} 
#inozh {
padding : 10px 0px 10px 10px; 
display : block; 
color : #ffffff; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#i367ii {
min-height : 100px; 
padding : 10px 0px 10px 0px; 
width : 50%; 
} 
#illm7i {
padding : 10px 0px 10px 0px; 
} 
#ihr8s4 {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#ifrzbt {
background-color : transparent; 
} 
#i65x8n {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#iygjnf {
background-color : transparent; 
} 
#i269cz {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#iyid9o {
background-color : transparent; 
} 
#ispf5r {
padding : 10px; 
display : block; 
font-size : 45px; 
text-align : center; 
color : white; 
} 
.campos-contacto {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#3c3c3c 0%, #3c3c3c 100%); 
width : 100%; 
padding : 10px 10px 10px 10px; 
border : 2px solid #ffffff; 
font-family : Montserrat; 
color : #ffffff; 
} 
#ivb8of {
padding : 10px; 
display : flex; 
justify-content : flex-end; 
} 
#im973i {
padding : 10px 20px 10px 20px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(246,0,0,0.5) 0%, rgba(246,0,0,0.5) 100%); 
color : #ffffff; 
font-size : 24px; 
font-family : Montserrat; 
width : 160px; 
} 
#imtnsk {
padding : 10px; 
} 
#i2qszk {
padding : 10px; 
display : block; 
color : #ffffff; 
text-align : center; 
} 
.item {
height : 200px; 
margin-top : 50px; 
margin-right : auto; 
margin-bottom : 50px; 
margin-left : auto; 
padding-top : 75px; 
text-align : center; 
} 
#i0uyoc {
min-height : 100px; 
display : flex; 
} 
.tarjeta-testimonial {
width : 300px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.2) ; 
margin : 70px 10px 10px 10px; 
} 
#iuapqr {
padding : 10px 0px 10px 0px; 
display : flex; 
justify-content : space-between; 
flex-wrap : wrap; 
z-index : 3; 
position : relative; 
} 
#ig5e5f {
padding : 10px 0px 25px 0px; 
width : 50%; 
align-items : center; 
} 
#im9g2x {
color : black; 
width : 100%; 
} 
#ilfx8a {
padding : 10px 0px 10px 10px; 
width : 50%; 
display : flex; 
align-items : center; 
} 
#i62gd {
min-height : 100px; 
padding : 10px 10px 10px 0px; 
} 
#i2alg {
padding : 120px 5% 120px 5%; 
min-height : 100px; 
justify-content : space-around; 
} 
#igzrx {
padding : 10px; 
min-height : 100px; 
} 
#i3ccyu {
padding : 10px; 
min-height : 100px; 
margin : 50px 0px 10px 0px; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
justify-content : space-between; 
width : 40%; 
box-shadow : 0px 0px 16px 0px rgba(0,0,0,0.25) ; 
border : 1px solid #f60000; 
} 
#i32chu {
padding : 10px; 
border-radius : 50% 50% 50% 50%; 
color : #ffffff; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#b70000 0%, #b70000 100%); 
} 
#i32chu:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#303030 0%, #303030 100%); 
} 
#itb8q2 {
padding : 10px; 
min-height : 100px; 
width : 75%; 
} 
#ix5eog {
padding : 10px; 
display : block; 
font-family : Montserrat; 
font-size : 28px; 
color : #b70000; 
font-weight : 600; 
} 
#izrqeh {
padding : 10px; 
display : block; 
font-family : Montserrat; 
font-size : 16px; 
color : #8b8b8b; 
} 
#i61c44 {
min-height : 100px; 
padding : 10px 0px 10px 0px; 
} 
#izmiak {
padding : 10px; 
min-height : 100px; 
} 
#infbh {
color : #b70000; 
} 
#ikq21p {
color : black; 
width : 500px; 
} 
.backgroundprincipal {
font-family : Montserrat; 
__background-type : image; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk2ilvvpyf8i.png'); 
} 
#iu4568 {
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-around; 
} 
#i5168l {
color : black; 
} 
#ivsl9l {
color : black; 
width : 15px; 
} 
#ijorsa {
color : black; 
} 
#i13qdh {
padding : 10px 10% 10px 10%; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-around; 
} 
#ipxsda {
padding : 10px; 
display : flex; 
justify-content : space-around; 
align-self : center; 
} 
#i6qam9 {
padding : 10px; 
display : block; 
color : #f60000; 
font-size : 82px; 
} 
#i4fgxk {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(246,0,0,0.5) 0%, rgba(246,0,0,0.5) 100%); 
position : absolute; 
width : 85%; 
top : 88%; 
right : 0px; 
} 
#i5iwlc {
padding : 10px 0px 10px 0px; 
min-height : 100px; 
margin : 120px 0 0 0; 
} 
#i1a2e2 {
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#d9d9d9 0%, #d9d9d9 100%); 
display : flex; 
justify-content : flex-end; 
} 
#ih4brn {
padding : 10px 10px 10px 50px; 
display : block; 
color : #f60000; 
font-size : 82px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
width : 35%; 
} 
.item-info {
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
flex-direction : row; 
} 
#iijj63 {
padding : 10px; 
display : block; 
font-family : Montserrat; 
font-size : 28px; 
color : #b70000; 
font-weight : 600; 
} 
#inl3fr {
padding : 10px; 
display : block; 
font-family : Montserrat; 
font-size : 16px; 
color : #8b8b8b; 
} 
#itraap {
padding : 10px; 
min-height : 100px; 
width : 75%; 
} 
#inn52i {
padding : 10px; 
min-height : 100px; 
margin : 50px 0px 10px 0px; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
justify-content : space-between; 
width : 40%; 
box-shadow : 0px 0px 16px 0px rgba(0,0,0,0.25) ; 
border : 1px solid #f60000; 
} 
#i9u5nf {
padding : 10px; 
display : block; 
font-family : Montserrat; 
font-size : 28px; 
color : #b70000; 
font-weight : 600; 
} 
#ivbjb3 {
padding : 10px; 
display : block; 
font-family : Montserrat; 
font-size : 16px; 
color : #8b8b8b; 
} 
#i3nkjw {
padding : 10px; 
min-height : 100px; 
width : 75%; 
} 
#i4dziv {
padding : 10px; 
min-height : 100px; 
margin : 50px 0px 10px 0px; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
justify-content : space-between; 
width : 40%; 
box-shadow : 0px 0px 16px 0px rgba(0,0,0,0.25) ; 
border : 1px solid #f60000; 
} 
#iljrou {
padding : 10px 50px 10px 10px; 
display : block; 
color : #f60000; 
font-size : 82px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
width : 35%; 
text-align : right; 
} 
#ius19c {
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#d9d9d9 0%, #d9d9d9 100%); 
display : flex; 
justify-content : flex-start; 
} 
#i8w4dl {
padding : 10px 0px 10px 0px; 
min-height : 100px; 
} 
#ic332g {
padding : 10px; 
display : block; 
font-family : Montserrat; 
font-size : 28px; 
color : #b70000; 
font-weight : 600; 
} 
#iwwuze {
padding : 10px; 
display : block; 
font-family : Montserrat; 
font-size : 16px; 
color : #8b8b8b; 
} 
#im454f {
padding : 10px; 
min-height : 100px; 
width : 75%; 
} 
#ihfuyv {
padding : 10px; 
min-height : 100px; 
margin : 50px 0px 10px 0px; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
justify-content : space-between; 
width : 40%; 
box-shadow : 0px 0px 16px 0px rgba(0,0,0,0.25) ; 
border : 1px solid #f60000; 
} 
#igrplm {
padding : 10px; 
display : block; 
font-family : Montserrat; 
font-size : 28px; 
color : #b70000; 
font-weight : 600; 
} 
#i6ci6f {
padding : 10px; 
display : block; 
font-family : Montserrat; 
font-size : 16px; 
color : #8b8b8b; 
} 
#ivja9p {
padding : 10px; 
min-height : 100px; 
width : 75%; 
} 
#i0y9sl {
padding : 10px; 
min-height : 100px; 
margin : 50px 0px 10px 0px; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
justify-content : space-between; 
width : 40%; 
box-shadow : 0px 0px 16px 0px rgba(0,0,0,0.25) ; 
border : 1px solid #f60000; 
} 
#i43pv2 {
padding : 10px; 
display : block; 
font-family : Montserrat; 
font-size : 28px; 
color : #b70000; 
font-weight : 600; 
} 
#ijqop8 {
padding : 10px; 
display : block; 
font-family : Montserrat; 
font-size : 16px; 
color : #8b8b8b; 
} 
#ix142j {
padding : 10px; 
min-height : 100px; 
width : 75%; 
} 
#il84dp {
padding : 10px; 
min-height : 100px; 
margin : 50px 0px 10px 0px; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
justify-content : space-between; 
width : 40%; 
box-shadow : 0px 0px 16px 0px rgba(0,0,0,0.25) ; 
border : 1px solid #f60000; 
} 
#in2zjr {
padding : 10px; 
min-height : 100px; 
} 
#it70au {
min-height : 100px; 
padding : 10px 0px 10px 0px; 
} 
#irbt2i {
min-height : 100px; 
padding : 10px 10px 10px 0px; 
} 
#i9k90x {
padding : 120px 5% 120px 5%; 
min-height : 100px; 
justify-content : space-around; 
} 
#i46gwu {
padding : 10px; 
display : block; 
font-family : Montserrat; 
font-size : 28px; 
color : #b70000; 
font-weight : 600; 
} 
#i6t2gv {
padding : 10px; 
display : block; 
font-family : Montserrat; 
font-size : 16px; 
color : #8b8b8b; 
} 
#ilprw6 {
padding : 10px; 
min-height : 100px; 
width : 75%; 
} 
#i8hzwa {
padding : 10px; 
min-height : 100px; 
margin : 50px 0px 10px 0px; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
justify-content : space-between; 
width : 40%; 
box-shadow : 0px 0px 16px 0px rgba(0,0,0,0.25) ; 
border : 1px solid #f60000; 
} 
#il8k1j {
padding : 10px; 
display : block; 
font-family : Montserrat; 
font-size : 28px; 
color : #b70000; 
font-weight : 600; 
} 
#idoj0p {
padding : 10px; 
display : block; 
font-family : Montserrat; 
font-size : 16px; 
color : #8b8b8b; 
} 
#i2rnww {
padding : 10px; 
min-height : 100px; 
width : 75%; 
} 
#i5cjqj {
padding : 10px; 
min-height : 100px; 
margin : 50px 0px 10px 0px; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
justify-content : space-between; 
width : 40%; 
box-shadow : 0px 0px 16px 0px rgba(0,0,0,0.25) ; 
border : 1px solid #f60000; 
} 
#iobyrj {
padding : 10px; 
display : block; 
font-family : Montserrat; 
font-size : 28px; 
color : #b70000; 
font-weight : 600; 
} 
#i7dv09 {
padding : 10px; 
display : block; 
font-family : Montserrat; 
font-size : 16px; 
color : #8b8b8b; 
} 
#iw1aq6 {
padding : 10px; 
min-height : 100px; 
width : 75%; 
} 
#i2vjm8 {
padding : 10px; 
min-height : 100px; 
margin : 50px 0px 10px 0px; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
justify-content : space-between; 
width : 40%; 
box-shadow : 0px 0px 16px 0px rgba(0,0,0,0.25) ; 
border : 1px solid #f60000; 
} 
#ikxkxh {
padding : 10px; 
min-height : 100px; 
width : 50%; 
display : flex; 
align-items : center; 
justify-content : space-around; 
} 
#i5ajok {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#igc5kf {
padding : 10px 10% 10px 10%; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
} 
#i1fr26 {
color : black; 
} 
#iqfi7v {
padding : 10px; 
min-height : 100px; 
width : 25%; 
display : flex; 
align-items : center; 
justify-content : space-around; 
} 
#idqgxz {
padding : 10px; 
border-radius : 50% 50% 50% 50%; 
color : #ffffff; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#b70000 0%, #b70000 100%); 
} 
#idqgxz:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#303030 0%, #303030 100%); 
} 
#ipjf5e {
padding : 10px; 
min-height : 100px; 
width : 25%; 
display : flex; 
align-items : center; 
justify-content : space-around; 
} 
#i3ix44 {
padding : 10px; 
border-radius : 50% 50% 50% 50%; 
color : #ffffff; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#b70000 0%, #b70000 100%); 
} 
#i3ix44:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#303030 0%, #303030 100%); 
} 
#i1rzgy {
padding : 10px; 
min-height : 100px; 
width : 25%; 
display : flex; 
align-items : center; 
justify-content : space-around; 
} 
#iaissi {
padding : 10px; 
border-radius : 50% 50% 50% 50%; 
color : #ffffff; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#b70000 0%, #b70000 100%); 
} 
#iaissi:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#303030 0%, #303030 100%); 
} 
#i7u9gn {
padding : 10px; 
min-height : 100px; 
width : 25%; 
display : flex; 
align-items : center; 
justify-content : space-around; 
} 
#igesk2 {
padding : 10px; 
border-radius : 50% 50% 50% 50%; 
color : #ffffff; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#b70000 0%, #b70000 100%); 
} 
#igesk2:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#303030 0%, #303030 100%); 
} 
#ilvv9p {
padding : 10px; 
min-height : 100px; 
width : 25%; 
display : flex; 
align-items : center; 
justify-content : space-around; 
} 
#i2octj {
padding : 10px; 
border-radius : 50% 50% 50% 50%; 
color : #ffffff; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#b70000 0%, #b70000 100%); 
} 
#i2octj:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#303030 0%, #303030 100%); 
} 
#id6cbq {
padding : 10px; 
min-height : 100px; 
width : 25%; 
display : flex; 
align-items : center; 
justify-content : space-around; 
} 
#iafxd5 {
padding : 10px; 
border-radius : 50% 50% 50% 50%; 
color : #ffffff; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#b70000 0%, #b70000 100%); 
} 
#iafxd5:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#303030 0%, #303030 100%); 
} 
#i4g3vk {
padding : 10px; 
min-height : 100px; 
width : 25%; 
display : flex; 
align-items : center; 
justify-content : space-around; 
} 
#iz782j {
padding : 10px; 
border-radius : 50% 50% 50% 50%; 
color : #ffffff; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#b70000 0%, #b70000 100%); 
} 
#iz782j:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#303030 0%, #303030 100%); 
} 
#ivw2ch {
padding : 10px; 
min-height : 100px; 
width : 25%; 
display : flex; 
align-items : center; 
justify-content : space-around; 
} 
#i4g3yn {
padding : 10px; 
border-radius : 50% 50% 50% 50%; 
color : #ffffff; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#b70000 0%, #b70000 100%); 
} 
#i4g3yn:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#303030 0%, #303030 100%); 
} 
#ii33y5 {
padding : 10px; 
min-height : 100px; 
width : 25%; 
display : flex; 
align-items : center; 
justify-content : space-around; 
} 
#is5eph {
padding : 10px; 
min-height : 100px; 
width : 147px; 
display : flex; 
align-items : center; 
justify-content : space-between; 
} 
#imj1uz {
display : block; 
padding : 10px 10px 10px 10px; 
border-radius : 10px 10px 10px 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(246,0,0,0.5) 0%, rgba(246,0,0,0.5) 100%); 
} 
#id3wfp {
color : black; 
} 
#i3qsiz {
color : black; 
} 
#iak5x5 {
display : block; 
padding : 10px 10px 10px 10px; 
border-radius : 10px 10px 10px 10px; 
} 
.circulo {
width : 50px; 
min-height : 50px; 
} 
#iay8fh {
color : #ffffff; 
} 
#iak5x5:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(246,0,0,0.5) 0%, rgba(246,0,0,0.5) 100%); 
} 
.img-sobremi {
margin : 35px 0px 35px 0px; 
} 
#i8ji75 {
color : black; 
width : 100%; 
} 
#i86czz {
color : black; 
width : 100%; 
} 
.swiper {
width : 100%; 
height : 300px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
#i39up1 {
padding : 10px; 
} 
#i84icg {
padding : 10px; 
} 
.swiper.mySwiper {
display : none; 
} 
#ib5xjl {
padding : 10px; 
} 

  @media only screen and (max-width: 992px) {#ij34 {
padding : 10px 10px 10px 10px; 
}} 
@media only screen and (max-width: 992px) {#icviq {
width : 319.07682373046873px; 
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#ihonp {
padding : 10px 10px 10px 10px; 
min-height : 439px; 
}} 
@media only screen and (max-width: 992px) {.tarjeta-testimonial {
width : 225px; 
}} 
@media only screen and (max-width: 992px) {#i2alg {
padding : 10px 10px 10px 10px; 
}} 
@media only screen and (max-width: 992px) {#i9k90x {
padding : 10px 10px 10px 10px; 
}} 
@media only screen and (max-width: 992px) {#i6qam9 {
font-size : 46px; 
}} 
@media only screen and (max-width: 992px) {#ih4brn {
font-size : 46px; 
}} 
@media only screen and (max-width: 992px) {#iljrou {
font-size : 46px; 
}} 
@media only screen and (max-width: 992px) {.item-info {
justify-content : space-around; 
}} 
@media only screen and (max-width: 992px) {#i3ccyu {
justify-content : space-around; 
width : 60%; 
}} 
@media only screen and (max-width: 992px) {#i4dziv {
width : 60%; 
}} 
@media only screen and (max-width: 992px) {#inn52i {
width : 60%; 
}} 
@media only screen and (max-width: 992px) {#ihfuyv {
width : 60%; 
}} 
@media only screen and (max-width: 992px) {#i0y9sl {
width : 60%; 
}} 
@media only screen and (max-width: 992px) {#il84dp {
width : 60%; 
}} 
@media only screen and (max-width: 992px) {#i8hzwa {
width : 60%; 
}} 
@media only screen and (max-width: 992px) {#i5cjqj {
width : 60%; 
}} 
@media only screen and (max-width: 992px) {#i2vjm8 {
width : 60%; 
}} 
@media only screen and (max-width: 992px) {.item-social {
width : 50px; 
min-height : 50px; 
}} 
@media only screen and (max-width: 992px) {#i13qdh {
display : block; 
padding : 10px 10px 10px 10px; 
}} 
@media only screen and (max-width: 992px) {#ipxsda {
width : 208.562px; 
}} 
@media only screen and (max-width: 992px) {#i0uyoc {
flex-wrap : wrap; 
}} 
@media only screen and (max-width: 992px) {#ig5e5f {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#im9g2x {
width : 75%; 
}} 
@media only screen and (max-width: 992px) {#iuapqr {
display : block; 
}} 
@media only screen and (max-width: 992px) {.backgroundprincipal.ancho-100 {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ilfx8a {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ikq21p {
width : 250px; 
}} 
@media only screen and (max-width: 992px) {#i8ji75 {
width : 75%; 
}} 
@media only screen and (max-width: 992px) {#i86czz {
width : 75%; 
}} 
@media only screen and (max-width: 992px) {.img-sobremi {
display : none; 
}} 
@media only screen and (max-width: 992px) {.swiper.mySwiper {
display : block; 
height : auto; 
}} 
@media only screen and (max-width: 992px) {.swiper-button-next {
color : #cb0000; 
}} 
@media only screen and (max-width: 992px) {.swiper-button-prev {
color : #cb0000; 
width : 27px; 
}} 
@media only screen and (max-width: 992px) {#igwqg2 {
color : black; 
}} 
@media only screen and (max-width: 992px) {#ioqfko {
color : black; 
}} 
@media only screen and (max-width: 992px) {#ikdwc1 {
color : black; 
}} 

  @media only screen and (max-width: 480px) {#ispf5r {
font-size : 35px; 
}} 
@media only screen and (max-width: 480px) {.tarjeta-testimonial {
width : 250px; 
}} 
@media only screen and (max-width: 480px) {#iep8l {
width : 100%; 
justify-content : space-around; 
}} 
@media only screen and (max-width: 480px) {#ig5e5f {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#izmiak {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#ilfx8a {
width : 100%; 
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#i62gd {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ikq21p {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#icviq {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#irbt2i {
width : 100%; 
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#ih4brn {
width : auto; 
}} 
@media only screen and (max-width: 480px) {#iljrou {
width : auto; 
}} 
@media only screen and (max-width: 480px) {.item-info {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#i2alg {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#i3ccyu {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i4dziv {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#inn52i {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ihfuyv {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i0y9sl {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#il84dp {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i8hzwa {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i5cjqj {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i2vjm8 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#izrqeh {
font-size : 13px; 
}} 
@media only screen and (max-width: 480px) {#ivbjb3 {
font-size : 13px; 
}} 
@media only screen and (max-width: 480px) {#inl3fr {
font-size : 13px; 
}} 
@media only screen and (max-width: 480px) {#iwwuze {
font-size : 13px; 
}} 
@media only screen and (max-width: 480px) {#ic332g {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#ix5eog {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#i9u5nf {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#iijj63 {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#igrplm {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#i43pv2 {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#i46gwu {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#il8k1j {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#iobyrj {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#i6ci6f {
font-size : 13px; 
}} 
@media only screen and (max-width: 480px) {#ijqop8 {
font-size : 13px; 
}} 
@media only screen and (max-width: 480px) {#i6t2gv {
font-size : 13px; 
}} 
@media only screen and (max-width: 480px) {#ikxkxh {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i367ii {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#im973i {
font-size : 16px; 
}} 
@media only screen and (max-width: 480px) {#izcqh {
font-size : 12px; 
}} 
@media only screen and (max-width: 480px) {#iu4568 {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#im9g2x {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#is5eph {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i8ji75 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i86czz {
width : 100%; 
}} 

}
  