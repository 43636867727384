
#id666717b4914da0d427fd7f8c {
  #ipax {
padding : 10px; 
min-height : 100vh; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(250,250,250,0.98) 0%, rgba(250,250,250,0.98) 100%); 
font-family : Montserrat; 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#ijtt {
padding : 10px; 
min-height : 100px; 
} 
#itjh {
padding : 10px; 
display : block; 
font-weight : 800; 
font-size : 4rem; 
text-align : center; 
} 
#iczg {
color : black; 
width : 956.9030881347657px; 
min-height : 10auto; 
} 
#iytc {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
.item {
height : 200px; 
margin-top : 50px; 
margin-right : auto; 
margin-bottom : 50px; 
margin-left : auto; 
padding-top : 75px; 
text-align : center; 
} 
#im9vk {
min-height : 100px; 
padding : 10px; 
} 
#ixm7l {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#iyd02 {
padding : 10px; 
text-align : center; 
display : inline-block; 
min-width : 50px; 
min-height : 30px; 
cursor : pointer; 
color : #fff; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#b70000 0%, #b70000 100%); 
border-radius : 10px 10px 10px 10px; 
width : 200px; 
} 
#iyd02:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#6f0404 0%, #6f0404 100%); 
} 
#i1rcf:hover  {
text-decoration : none; 
} 

  
  
}
  