
#id666713ef914da0d427fd7c3f {
  #ixru {
min-height : 100px; 
font-family : "Italiana", sans-serif; 
} 
#ij34 {
padding : 10px 10% 10px 10%; 
display : flex; 
justify-content : space-between; 
flex-wrap : wrap; 
} 
#i7z62 {
color : black; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#iep8l {
width : auto; 
display : flex; 
justify-content : flex-start; 
align-items : center; 
} 
#izcqh {
padding : 10px; 
display : block; 
color : white; 
font-family : Montserrat; 
} 
.icono-social {
width : 25px; 
} 
.item-social {
margin : 0px 10px 0px 10px; 
padding : 10px 10px 10px 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(217,217,217,0.2) 0%, rgba(217,217,217,0.2) 100%); 
width : 88px; 
min-height : 88px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
border : 1px solid #ffffff; 
border-radius : 50% 50% 50% 50%; 
} 
#i7mce {
padding : 10px; 
font-family : WindSong; 
color : #3ab5dd; 
} 
#icviq {
padding : 10px; 
width : 479.46759033203125px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
font-weight : 600; 
flex-wrap : wrap; 
font-size : 22px; 
} 
#ie1gg {
padding : 5px; 
width : auto; 
height : auto; 
display : block; 
color : black; 
text-decoration : none; 
} 
#iierj {
padding : 5px; 
width : auto; 
height : auto; 
display : block; 
color : #000000; 
text-decoration : none; 
} 
#iotjc {
padding : 5px; 
width : auto; 
height : auto; 
display : block; 
color : #000000; 
} 
.activo-pagina {
color : #b70000; 
text-decoration : none; 
} 
#i0kwi {
padding : 80px 10% 80px 10%; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#303030 0%, #303030 100%); 
width : 65%; 
flex-direction : column-reverse; 
} 
#ihonp {
padding : 60px 10% 60px 10%; 
position : relative; 
min-height : 530px; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#i367ii {
min-height : 100px; 
padding : 10px 0px 10px 0px; 
width : 100%; 
} 
#illm7i {
padding : 10px 0px 10px 0px; 
} 
#ihr8s4 {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#ifrzbt {
background-color : transparent; 
} 
#i65x8n {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#iygjnf {
background-color : transparent; 
} 
#i269cz {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#iyid9o {
background-color : transparent; 
} 
#ispf5r {
padding : 10px; 
display : block; 
font-size : 30px; 
text-align : center; 
color : white; 
} 
.campos-contacto {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#3c3c3c 0%, #3c3c3c 100%); 
width : 100%; 
padding : 10px 10px 10px 10px; 
border : 2px solid #ffffff; 
font-family : Montserrat; 
color : #ffffff; 
} 
#ivb8of {
padding : 10px; 
display : flex; 
justify-content : flex-end; 
} 
#im973i {
padding : 10px 20px 10px 20px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(246,0,0,0.5) 0%, rgba(246,0,0,0.5) 100%); 
color : #ffffff; 
font-size : 24px; 
font-family : Montserrat; 
width : 160px; 
} 
#imtnsk {
padding : 10px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk2ilvvpyf8i.png'); 
} 
#i2qszk {
padding : 10px; 
display : block; 
color : #ffffff; 
text-align : center; 
} 
.item {
height : 200px; 
margin-top : 50px; 
margin-right : auto; 
margin-bottom : 50px; 
margin-left : auto; 
padding-top : 75px; 
text-align : center; 
} 
#i0uyoc {
min-height : 100px; 
display : flex; 
flex-direction : column; 
} 
.tarjeta-testimonial {
width : 300px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.2) ; 
margin : 70px 10px 10px 10px; 
} 
#iuapqr {
padding : 10px 0px 10px 0px; 
display : flex; 
justify-content : space-between; 
flex-wrap : wrap; 
z-index : 3; 
position : relative; 
} 
#ig5e5f {
padding : 10px 0px 25px 0px; 
width : 50%; 
align-items : center; 
} 
#ikq21p {
color : black; 
width : 500px; 
} 
.backgroundprincipal {
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk2ilvvppq4a.png'); 
font-family : Montserrat; 
color : #ffffff; 
font-size : 25px; 
display : flex; 
flex-direction : column; 
justify-content : center; 
padding : 10px 20px 10px 20px; 
text-align : left; 
} 
#iu4568 {
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-around; 
} 
#i5168l {
color : black; 
} 
#ivsl9l {
color : black; 
width : 15px; 
} 
#ijorsa {
color : black; 
} 
#i13qdh {
padding : 10px 10% 10px 10%; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-around; 
} 
#ipxsda {
padding : 10px; 
display : flex; 
justify-content : space-around; 
align-self : center; 
} 
#i6qam9 {
padding : 10px; 
display : block; 
color : #f60000; 
font-size : 82px; 
} 
#i4fgxk {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(246,0,0,0.5) 0%, rgba(246,0,0,0.5) 100%); 
position : absolute; 
width : 85%; 
top : 50%; 
right : 0px; 
z-index : 3; 
} 
.item-info {
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
} 
#i9k90x {
padding : 120px 5% 120px 5%; 
min-height : 100px; 
justify-content : space-around; 
} 
#ikxkxh {
padding : 10px; 
min-height : 100px; 
width : 100%; 
display : flex; 
align-items : center; 
justify-content : flex-start; 
} 
#i5ajok {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#igc5kf {
padding : 10px 10% 10px 10%; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
} 
#i1fr26 {
color : black; 
} 
#iqbms5 {
padding : 10px; 
min-height : 222px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#d9d9d9 0%, #d9d9d9 100%); 
position : absolute; 
width : 70%; 
left : 0px; 
z-index : 1; 
bottom : 0px; 
} 
#iu06o {
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#303030 0%, #303030 100%); 
display : flex; 
flex-wrap : wrap; 
} 
#ilwnq {
padding : 120px 50px 120px 50px; 
min-height : 100px; 
__background-type : image; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : none; 
box-shadow : 0px 0px 16px 0px rgba(0,0,0,0.25) ; 
border : 1px solid #f60000; 
} 
#i11xz {
padding : 10px; 
min-height : 100px; 
width : 35%; 
} 
#izssc {
padding : 10px 20px 10px 20px; 
display : block; 
} 
#ikuzg {
padding : 10px 20px 10px 20px; 
display : block; 
margin : 25px 0 0 0; 
} 
#incnh {
color : #b70000; 
} 
#i19wl {
padding : 10px; 
min-height : 100px; 
width : 147px; 
display : flex; 
justify-content : space-between; 
align-items : center; 
} 
#i0iqjr {
display : block; 
padding : 10px 10px 10px 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(246,0,0,0.5) 0%, rgba(246,0,0,0.5) 100%); 
border-radius : 10px 10px 10px 10px; 
} 
#ip8fuh {
color : black; 
} 
#iyvurm {
color : black; 
} 
#i1jby9 {
display : block; 
padding : 10px 10px 10px 10px; 
border-radius : 10px 10px 10px 10px; 
} 
#i1jby9:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(246,0,0,0.5) 0%, rgba(246,0,0,0.5) 100%); 
} 
#iv7sxy {
color : #ffffff; 
} 
#idqrmb {
font-family : Montserrat; 
} 

  @media only screen and (max-width: 992px) {#ij34 {
padding : 10px 10px 10px 10px; 
}} 
@media only screen and (max-width: 992px) {#icviq {
width : 319.07682373046873px; 
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#ihonp {
padding : 10px 10px 10px 10px; 
min-height : 370px; 
}} 
@media only screen and (max-width: 992px) {.tarjeta-testimonial {
width : 225px; 
}} 
@media only screen and (max-width: 992px) {#i9k90x {
padding : 10px 10px 10px 10px; 
}} 
@media only screen and (max-width: 992px) {#i6qam9 {
font-size : 46px; 
}} 
@media only screen and (max-width: 992px) {.item-info {
justify-content : space-around; 
}} 
@media only screen and (max-width: 992px) {.item-social {
width : 50px; 
min-height : 50px; 
}} 
@media only screen and (max-width: 992px) {#i13qdh {
display : block; 
padding : 10px 10px 10px 10px; 
}} 
@media only screen and (max-width: 992px) {#ipxsda {
width : 208.562px; 
}} 
@media only screen and (max-width: 992px) {#i0uyoc {
flex-wrap : wrap; 
}} 
@media only screen and (max-width: 992px) {#iqbms5 {
min-height : 150px; 
}} 
@media only screen and (max-width: 992px) {.backgroundprincipal {
font-size : 16px; 
padding : 10px 10px 10px 10px; 
}} 
@media only screen and (max-width: 992px) {#i0kwi {
padding : 80px 10px 80px 10px; 
}} 
@media only screen and (max-width: 992px) {#ikq21p {
width : 250px; 
}} 

  @media only screen and (max-width: 480px) {#ispf5r {
font-size : 35px; 
}} 
@media only screen and (max-width: 480px) {.tarjeta-testimonial {
width : 250px; 
}} 
@media only screen and (max-width: 480px) {#iep8l {
width : 100%; 
justify-content : space-around; 
}} 
@media only screen and (max-width: 480px) {#ig5e5f {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ikq21p {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#icviq {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {.item-info {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#ikxkxh {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i367ii {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#im973i {
font-size : 16px; 
}} 
@media only screen and (max-width: 480px) {#izcqh {
font-size : 12px; 
}} 
@media only screen and (max-width: 480px) {#iu4568 {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#i0kwi {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ilwnq {
padding : 50px 5px 50px 5px; 
margin : 90px 0px 0px 0px; 
}} 
@media only screen and (max-width: 480px) {#iu06o {
display : block; 
}} 
@media only screen and (max-width: 480px) {#i11xz {
width : auto; 
}} 
@media only screen and (max-width: 480px) {#i19wl {
width : 1100%; 
}} 

}
  